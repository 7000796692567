import { Box, styled } from '@mui/material';

export const InfoPane = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  height: '100%',
}));

export const InfoCards = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr 1fr',
  gap: '24px',
  height: '50%',
}));

export const ICard = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  padding: '20px',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: '4px',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: '100%',
}));

export const MachineParams = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  height: '100%',
}));
