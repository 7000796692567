import React, { createContext, useEffect, useState } from 'react';
import { OEEContext as OEEContextType, TMachineState } from '../../../../../types';
import OEEGaugeChart from './OEEGaugeChart';

const mockData: OEEContextType['oeeData'][] = [
  {
    OEE: 79,
    availability: 90,
    performance: 95,
    quality: 92,
    availabilityData: { off: 18, ppt: 8, error: 15, manual: 15, rt: 432 },
    performanceData: { aoph: 9.5, ooph: 10 },
    qualityData: { dl: 5, tlp: 69, gl: 64 },
  },
  {
    OEE: 73,
    availability: 85,
    performance: 90,
    quality: 95,
    availabilityData: { off: 20, ppt: 8, error: 24, manual: 28, rt: 408 },
    performanceData: { aoph: 9, ooph: 10 },
    qualityData: { dl: 5, tlp: 64, gl: 61 },
  },
  {
    OEE: 79,
    availability: 90,
    performance: 95,
    quality: 92,
    availabilityData: { off: 18, ppt: 8, error: 15, manual: 15, rt: 432 },
    performanceData: { aoph: 9.5, ooph: 10 },
    qualityData: { dl: 5, tlp: 69, gl: 64 },
  },
  {
    OEE: 70,
    availability: 92,
    performance: 93,
    quality: 82,
    availabilityData: { off: 9, ppt: 8, error: 15, manual: 14, rt: 442 },
    performanceData: { aoph: 9.3, ooph: 10 },
    qualityData: { dl: 12, tlp: 67, gl: 55 },
  },
];

export const Context = createContext<OEEContextType | undefined>(undefined);

interface Props {
  machineState: TMachineState;
  controlVoltage: boolean;
}

export default function OEEProvider({ machineState, controlVoltage }: Props) {
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const disableOEE =
    machineState === 'stopped' ||
    (machineState as string) === 'Stopped' ||
    machineState === 'unknown' ||
    !controlVoltage;
  const [data, setData] = useState<OEEContextType>({
    oeeData: mockData[currentDataIndex],
    disableOEE,
  });

  useEffect(() => {
    if (disableOEE) {
      setData((prevState) => ({ ...prevState, disableOEE }));
      return;
    }

    const updateData = () => {
      setCurrentDataIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % mockData.length;
        setData((prevState) => ({ ...prevState, oeeData: mockData[newIndex] }));
        return newIndex;
      });
    };

    const interval = setInterval(updateData, 7 * 60 * 1000);

    return () => clearInterval(interval);
  }, [disableOEE]);

  return (
    <Context.Provider value={data}>
      <OEEGaugeChart />
    </Context.Provider>
  );
}
