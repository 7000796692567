import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import InfoSegment from './InfoSegment';
import useOEEContext from '../../../../../hooks/useOEEContext';

export default function OEEDetails() {
  const [open, setOpen] = React.useState(false);
  const {
    oeeData: { OEE, performanceData, availabilityData, performance, qualityData, quality, availability },
    disableOEE,
  } = useOEEContext();
  return (
    <>
      <IconButton
        disabled={disableOEE}
        onClick={() => setOpen(true)}
        sx={(theme) => ({ color: theme.palette.grey[100] })}
      >
        <InfoOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth PaperProps={{ sx: { py: 3 } }}>
        <DialogTitle variant='h5-bold'>Today&#39;s OEE Summary</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => setOpen(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 16,
            top: 36,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: -2 }}>
          <Typography variant='14-regular' sx={(theme) => ({ color: theme.palette.black[60] })}>
            OEE (Overall Equipment Effectiveness) is a key metric used to measure the efficiency and productivity of a
            machine. It is calculated using the following formula: OEE = Availability × Performance × Quality.
          </Typography>
          <Divider />
          <InfoSegment
            title='Availability'
            value={`${availability}%`}
            metaDataList={[
              `Planned Production Time: ${availabilityData.ppt} hours`,
              `Running Time: ${availabilityData.rt} min`,
              `Manual: ${availabilityData.manual} min`,
              `Off: ${availabilityData.off} min`,
              `Error: ${availabilityData.error} min`,
            ]}
          />
          <Divider />
          <InfoSegment
            title='Performance'
            value={`${performance}%`}
            metaDataList={[
              `Optimal Output per Hour: ${performanceData.ooph} Lenses`,
              `Actual Output per Hour: ${performanceData.aoph} Lenses`,
            ]}
          />
          <Divider />
          <InfoSegment
            title='Quality'
            value={`${quality}%`}
            metaDataList={[
              `Total Lenses Produced: ${qualityData.tlp}`,
              `Good Lenses: ${qualityData.gl}`,
              `Defective Lenses: ${qualityData.dl}`,
            ]}
          />
          <Box
            sx={{
              py: 1,
              px: 2,
              backgroundColor: (theme) => theme.palette.others.green[10],
              textAlign: 'center',
              mt: 1,
            }}
          >
            <Typography variant='16-bold'>OEE</Typography>
            <Typography variant='16-regular'>{` = ${availability}% x ${performance}% x ${quality}% = ${OEE}%`}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
