import { Box, Typography } from '@mui/material';
import GaugeChart from '../../../../../components/GaugeChart';
import { ICard } from './Overview.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OEEDetails from './OEEDetails';
import useOEEContext from '../../../../../hooks/useOEEContext';

export default function OEEGaugeChart() {
  const { t } = useTranslation();
  const {
    oeeData: { OEE },
    disableOEE,
  } = useOEEContext();
  return (
    <ICard sx={{ flexDirection: 'column', height: '100%', py: 4, px: 4 }}>
      <Box sx={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '28px', letterSpacing: '0.15px' }}>
            OEE
          </Typography>
          <OEEDetails />
        </Box>
        <Typography
          sx={(theme) => ({
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: theme.palette.black[60],
          })}
        >
          {`${t('lab:detailView:overview:availability:today')} `}
        </Typography>
      </Box>
      <GaugeChart value={disableOEE ? 0 : OEE} text={({ value }) => `${value}%`} />
    </ICard>
  );
}
