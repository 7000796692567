import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  value: string;
  metaDataList: string[];
}

export default function InfoSegment({ title, value, metaDataList }: Props) {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
        <Typography variant='16-bold'>{title}</Typography>
        <Typography variant='h6' sx={(theme) => ({ fontWeight: 700, color: theme.palette.others.green[100] })}>
          {value}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        {metaDataList.map((item) => (
          <Typography sx={(theme) => ({ color: theme.palette.black[60] })} variant='14-regular' key={item}>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
