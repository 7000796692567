import React from 'react';
import { Gauge, gaugeClasses, GaugeProps } from '@mui/x-charts/Gauge';

export default function GaugeChart(props: GaugeProps) {
  return (
    <Gauge
      {...props}
      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 18,
          fontWeight: 700,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: theme.palette.others.green[100],
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.others.green[20],
        },
      })}
      innerRadius='88%'
      outerRadius='100%'
    />
  );
}
