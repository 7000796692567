import React from 'react';
import { Context as OEEContext } from '../pages/Lab/containers/DetailView/Overview/OEEProvider';

export default function useOEEContext() {
  const context = React.useContext(OEEContext);
  if (!context) {
    throw new Error('useOEEContext must be used within an OEEProvider');
  }
  return context;
}
